
.form-text-field,
.form-select-input {
  label {
    display: block;
    padding: spacing(4) 0 spacing(2);
    font-size: 1.1em;
  }

  .MuiInputBase-input {
    background: #ffffff;
    border-radius: 0;
    font-size: 1.72em;
    line-height: 1;
    color: $colorSecondary;
    padding-left: spacing(2.5);
  }
}
