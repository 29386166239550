
.search-page {
  padding: spacing(2) 0 spacing(2);

  .id-input-form {
    width: 100%;
    border-radius: 0;

    .submit-button {
      border-radius: 0;
    }
  }
}
