
.email-template-editing-panel {
  .panel-body {
    width: 100%;
    min-height: spacing(30);

    .label{
      margin-bottom: spacing(2);
    }
  }
  .editor-body {
    min-height: spacing(50);
  }
}
