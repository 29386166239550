@import "./variables.scss";
@import "./functions.scss";
@import "./base.scss";
@import "./loader.scss";
@import "./step-row.scss";
@import "./form-text-field.scss";
@import "./video-uploader.scss";
@import "./qrcode-scanner.scss";
@import "./image-uploader.scss";
@import "./employee-search-box.scss";
@import "./search-form.scss";
@import "./navigation-bar.scss";
@import "./page-header.scss";
@import "./check-in-form.scss";
@import "./email-template-editing-panel.scss";
@import "./nda-editing-panel.scss";
@import "./app.scss";
@import "./litmon.scss";
@import "./search.scss";
@import "./litmon-document.scss";
@import "./location-template.scss";
@import "./person-template.scss";
@import "./guest-track-login.scss";
@import "./guest-track-home.scss";
@import "./guest-track-check-in.scss";
@import "./guest-track-admin-console.scss";

.text-gray {
  color: $grey400;
}
.highcharts-series-0 {
  .highcharts-point {
    stroke: #e0e0e0;
  }
}
