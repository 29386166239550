
.image-uploader {
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid $grey400;

  .preview-window {
    padding: spacing(2);

    .drop-area {
      // background-size: cover;
      // background-position: center center;
      padding-top: 100%;
      background-color: $grey100;
      position: relative;

      .preview-image-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 0;
        overflow: hidden;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .info-area {
    text-align: center;
    padding: 0 spacing(2) spacing(2);

    .label {
      padding: 0 spacing(1);
      margin-bottom: spacing(2);
      line-height: 1.5;
    }
  }

  .progress-bar {
    background-color: $grey300;
  }
}
