
$colorPrimary: #ec1c24;
$colorSecondary: #007dc8;

$blue: rgb(0, 125, 200);
$lightBlue: rgb(100, 200, 250);

$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #bdbdbd;
$grey500: #9e9e9e;

$baseSpacing: 8;
