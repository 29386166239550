
.step-row {
  &:first-child {
    .step-stop {
      padding-top: spacing(4);
    }

    .step-content {
      padding-top: spacing(4)
    }
  }

  &:last-child {
    .step-stop {
      padding-bottom: spacing(16);
    }
  }

  .step-stop {
    height: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    padding: spacing(2) spacing(1) spacing(8);
    text-align: center;

    .line {
      display: inline-block;
      box-sizing: content-box;
      width: 0;
      position: absolute;
      z-index: 10;
      top: 0;
      left: calc(50% - 1px);
      bottom: 0;
      border-right: 2px solid $colorPrimary;
    }

    .step-index {
      display: inline-block;
      position: relative;
      z-index: 20;
      background-color: white;
      width: spacing(5);
      height: spacing(5);
      border-radius: spacing(5);
      border: 2px solid $colorPrimary;
      text-align: center;
      line-height: spacing(5);

      .MuiTypography-root {
        line-height: 2.5rem;
        display: inline;
      }
    }
  }

  .step-content {
    padding: spacing(2) spacing(1) spacing(4);
  }
}
