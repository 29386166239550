
.navigation-bar {
  background-color: #ffffff;
  
  & .logo {
    height: spacing(5);
  }

  & .spacing {
    flex-grow: 1;
  }

  & .nav-link {
    margin-right: spacing(1);

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $colorPrimary;
    }
  }
}
