
.guest-track-login-page {
  padding: spacing(10) 0;

  .main-logo {
    display: block;
    max-width: 85%;
    margin: 0 0 spacing(12);
  }

  .MuiStepper-root {
    background: transparent;
  }

  .MuiStepIcon-completed {
    color: green;
  }

  .page-header {
    margin-bottom: spacing(5);
  }

  .login-steps {
    margin-bottom: spacing(2);

    .loaded-info {
      padding: spacing(1);
      border-left: 1px dotted #c5e2c5;
      margin-bottom: spacing(1);
      background-image: linear-gradient(45deg, #caf5c1, rgba(255,255,255,0));
    }
  }

  .actions-container {
    text-align: right;
  }
}
