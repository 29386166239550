
.admin-console-page {
  padding: spacing(8) 0 spacing(20);

  .setting-section {
    margin-bottom: spacing(2);

    .title {
      margin-bottom: spacing(2);
    }
  }

  .nda-body {
    line-height: 1.675;
  }

  .editor-body {
    min-height: spacing(50);
  }
}
