
.litmon-document {
  padding-bottom: spacing(20);

  .video {
    width: 100%;
    max-height: 600px;
    margin-bottom: spacing(2);
    background-color: $grey200;
  }

  .info-container {
    margin-top: spacing(5);

    .MuiTabs-root {
      border-bottom: 1px solid $grey300;
  
      .MuiTabs-indicator {
        background-color: transparent;
      }
    }
  
    .step-row {
      .step-content {
        .MuiTypography-root {
          display: inline-block;
          line-height: 1.25;
        }
      }
    }
  }
}
