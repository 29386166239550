
.loading-indicator {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  padding-top: 75vh;
  text-align: center;
  background-image: linear-gradient(to top, #e0e0e0, #e8e8e8, rgba(255,255,255,0));

  .label-text {
    margin-bottom: spacing(2.5);
  }
}