
.litmon-page {
  padding: spacing(8) 0 spacing(40);

  .page-header {
    margin-bottom: spacing(4);
  }

  .qr-scanner {
    margin-top: spacing(2);
  }

  .person-card {
    margin-top: spacing(2);
  }

  .step-row {
    .step-stop {
      padding-bottom: spacing(2);

      .line {
        border-right: 1px solid $grey400;
      }

      .add-btn, .remove-btn {
        position: relative;
        z-index: 20;
        font-size: 1rem;
        box-shadow: none;
      }

      .remove-btn {
        background-color: white;
        border: 1px solid $grey400;
        transition: all 200ms;

        &:hover {
          color: $colorPrimary;
        }
      }
    }
    .step-content {
      padding-bottom: 0;
    }
  }

  .submit-button {
    padding: spacing(1.5);
  }
}
