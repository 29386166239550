
html, body {
  margin: 0;
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  height: 100vh;
  width: 100%;
  background-color: $grey50;
}

.info-message-container {
  text-align: center;
  margin-top: spacing(10);
}

/**
 * BEGIN: Material UI Button
 */
.MuiButton-root {
  border-radius: 0;
  text-transform: initial;

  & .MuiButton-label {
    font-weight: 500;
  }
}

img {
  max-width: 100%;
}
