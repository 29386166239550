.guest-track-check-in-page {
  padding: spacing(10) 0 spacing(20);

  .page-header {
    margin-bottom: spacing(5);
  }

  .form-header {
    background-color: #ffffff;
    padding: spacing(2);
    border-radius: 5px;
    border: 1px solid $grey400;
  }

  .form-label {
    padding: spacing(1);
    margin-bottom: spacing(3);
    margin-top: spacing(8);
    letter-spacing: 0.08em;
    line-height: 1;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.35);
  }

  .MuiCardHeader-root {
    padding: 5px;
  }
}
