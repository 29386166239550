
.video-uploader {
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .input-area {
    padding: spacing(2);
    background-color: $grey200;
    height: spacing(40);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label {
      margin-bottom: spacing(1);
      line-height: 1.5;
      text-align: center;
    }

    .file-info {
      margin-top: spacing(6);
    }
  }

  .progress-bar {
    background-color: $grey300;
  }
}
