
.check-in-form {
  .nda-preview {
    background-color: #ffffff;
    padding: spacing(2) spacing(2) spacing(10);
    outline: 4px dashed #ff9449;
    margin-bottom: spacing(3);
    line-height: 1.675;
    // box-shadow: rgba(0,0,0,0.15) 0 0 12px;
  }
}