
.guest-track-home {
  padding: spacing(10) 0;

  .header {
    margin-bottom: spacing(15);
    text-align: center;

    .company-logo {
      max-width: 90%;
    }

    .the-title {
      // font-style: italic;
    }
  }

  .big-btn {
    font-size: 1.8em;
    box-shadow: rgba(0,0,0,0.15) 0px 8px 16px;
  }

  .other-actions {
    margin-top: spacing(20);
  }
}
