
.page-header {
  .title {
    line-height: 1;
  }
  .subtitle {
    display: inline-block;
    line-height: 1.25;
    margin-top: spacing(1.2);
  }
}
