
.search-form {
  display: inline-flex;
  align-items: center;
  background-color: $grey200;
  border-radius: 100px;
  overflow: hidden;

  .text-field {
    flex-grow: 1;
    font-size: 1.2rem;
    background-color: transparent;
    border: none;
    height: spacing(7);
    padding: 0 spacing(2.5);

    &:focus {
      outline: none;
    }
  }

  .submit-button {
    flex-shrink: 0;
    box-shadow: none;
  }
}